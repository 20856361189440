<template>
  <q-page class="flex flex-center">
    <div>
      <!-- <img class="pulse" alt="authenticating" src="/statics/icons/icon-gold.svg" height="63" style="vertical-align: middle; display: block; margin: auto;"> -->
      <q-spinner-puff size="200px" class="loading-spinner loading-spinner-gold" style="color: #F4A724; display: block; margin-top: -134px;"/>
    </div>
  </q-page>
</template>

<script>

export default {
  name: 'PageLogout',
  props: ['auth'],
  data () {
    this.auth.logout()
    return {}
  }
}
</script>
